import React, {Component} from 'react';
import {Container, List, Label, Card, CardHeader, CardBody} from "reactstrap";
import {Link} from "react-router-dom";
import './Resume.css';

export class Resume extends Component {
    static displayName = Resume.name;

    render() {
        return (
            <Container className="resume_layoutContainer">
                <Container className="resume_profileContainer shadow-lg">
                    <Container className="resume_innerProfileContainer">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <img src="images/keithavatar.png" alt="Keith Hamilton" className="resume_profilePic d-block mx-auto mx-md-0"  />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <Container className="resume_headerRight">
                                    <Container>
                                        <div className="row">
                                            <Container className="col-12 resume_textRight">
                                                <Label>Keith Hamilton</Label>
                                            </Container>
                                        </div>
                                        <div className="row">
                                            <Container className="col-12 resume_textRight">
                                                <Label>(916) 527-4136</Label>
                                            </Container>
                                        </div>
                                        <div className="row">
                                            <Container className="col-12 resume_textRight">
                                                <Link to="mailto:keith@keithsresume.com">
                                                    <Label>keith@keithsresume.com</Label>
                                                </Link>
                                            </Container>
                                        </div>
                                        <div className="row resume_textRight">
                                            <Container className="col-12">
                                                <Label>Download other versions:</Label>
                                                &nbsp;&nbsp;                                                
                                                <a href="/doc/keiths-resume.pdf">
                                                    <Label>PDF</Label>
                                                </a>
                                                &nbsp;&nbsp;
                                                <a href="/doc/keiths-resume.docx">
                                                    <Label>DOCX</Label>    
                                                </a>                                                
                                            </Container>                                            
                                        </div>
                                    </Container>
                                </Container>
                            </div>
                        </div>
                    </Container>
                </Container>

                <Container className="resume_employmentExperience">
                    <h2>Employment Experience</h2>
                </Container>

                <Card className="my-2">
                    <CardHeader>
                        <strong>2017 - Present</strong>
                        &nbsp;&nbsp;
                        Software Engineer / Data Migration Lead for Runding Corporation
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <Container>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>Trimble</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <List>
                                            <li>
                                                <p>
                                                    Contributed to AppXchange “Flows” implemented in either C# or Javascript, 
                                                    enabling the integration of data from numerous ERP systems such as 
                                                    ServiceTitan, Vista, Spectrum, BuildOps, Kojo, and others.
                                                </p>
                                            </li>
                                        </List>
                                    </div>
                                </div>
                            </Container>
                            
                            <Container>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>Ontario Ministry of Transportation</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <List>
                                            <li>
                                                <p>
                                                    Directed a team in migrating data from an existing Microsoft SQL
                                                    database application into Kahua, ensuring a seamless and successful
                                                    transition.
                                                </p>
                                            </li>
                                        </List>
                                    </div>
                                </div>
                            </Container>

                            <Container>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>General Services Administration</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <List>
                                            <li>
                                                <p>
                                                    Took charge of developing a hosted service responsible for
                                                    migrating data from an existing Proliance system into Kahua,
                                                    streamlining the process and ensuring accurate data transfer.
                                                </p>
                                            </li>
                                        </List>
                                    </div>
                                </div>
                            </Container>

                            <Container>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>Tilson</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <List>
                                            <li>
                                                <p>
                                                    Designed and implemented project management controls leveraging the
                                                    capabilities of the Microsoft Power Platform, enhancing project
                                                    efficiency and coordination.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Orchestrated the creation of Azure Functions, seamlessly integrating
                                                    an existing Sage instance with the Microsoft Power Platform,
                                                    optimizing data flow and expanding system capabilities.
                                                </p>
                                            </li>
                                        </List>
                                    </div>
                                </div>
                            </Container>

                            <Container>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>Apogos</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <List>
                                            <li>
                                                <p>
                                                    Developed a comprehensive project controls and
                                                    resource planning solution by leveraging the Microsoft Power
                                                    Platform, enhancing project management and resource allocation
                                                    efficiency.
                                                </p>
                                            </li>
                                        </List>
                                    </div>
                                </div>
                            </Container>

                            <Container>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>Disney Imagineering</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <List>
                                            <li>
                                                <p>
                                                    Was actively involved in developing Docusign integration and
                                                    JavaScript
                                                    modules within NexGEN, a NodeJS based web application providing a
                                                    custom user interface for an existing Proliance installation.
                                                </p>
                                            </li>
                                        </List>
                                    </div>
                                </div>
                            </Container>

                            <Container>
                                <div className="row">
                                    <div className="col-12">
                                        <strong>Simon Properties</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <List>
                                            <li>
                                                <p>
                                                    Created a VB.NET application to facilitate the seamless import of
                                                    multiple uncommitted costs across Proliance projects or a single
                                                    uncommitted cost invoice into a specific project.
                                                </p>
                                            </li>
                                        </List>
                                    </div>
                                </div>
                            </Container>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>2012 - 2017</strong>
                        &nbsp;&nbsp;
                        Software Engineer / Systems Administrator for Axiom Analytix
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Headed a small team in the development of data migrations, transforming
                                                SACS (Standardized Account Code Structure) data files into a Microsoft
                                                SQL database to enable the generation
                                                of reports for school officials and parents in the California School
                                                System.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Took charge of the development and ongoing maintenance of VIA, a
                                                comprehensive solution designed to analyze and assess the performance of
                                                schools and districts across California.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Led the development and continuous maintenance of DataPort, a dynamic
                                                form building solution utilized for collecting survey information across
                                                various scenarios. The platform was built using C#, .NET WebForms,
                                                jQuery, and jQueryUI.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Responsible for the creation and upkeep of Axiom Import Tool, an
                                                innovative, multi-threaded, plug-in based import tool developed using C#
                                                and .NET WinForms. The tool incorporated a dynamic compiler capable of
                                                compiling small programs at runtime.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Diligently maintained and updated Truancy Hunter, a C# .NET WebForms
                                                application responsible for tracking student truant data, from the
                                                initial truancy occurrence through the entire SARB (Student Attendance
                                                Review Board) process.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Facilitated seamless communication between Truancy Hunter and school
                                                officials by integrating emails through a postfix instance, allowing
                                                notifications upon parents opening emails related to their truant child.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Oversaw the maintenance of core business websites, applications,
                                                servers, and employee computers, ensuring their smooth operation and
                                                optimal performance.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Designed and implemented custom applications for web data scraping,
                                                occasionally utilizing Selenium to enhance data retrieval capabilities.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Generated comprehensive reports using Microsoft Reporting Services,
                                                providing valuable insights to support decision-making processes.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Implemented an innovative solution utilizing Tesseract OCR, making
                                                scanned school documents searchable and easily accessible to customer
                                                users.
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>2006 - 2009</strong>
                        &nbsp;&nbsp;
                        Software Engineer for Entersolve, Inc.
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <strong>Entersolve</strong>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Successfully led the migration of multiple custom Microsoft Access
                                                databases to advanced solutions built on Microsoft SQL, improving data
                                                management and accessibility.
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>

                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <strong>Transfirst</strong>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Developed and maintained ELAPP (Electronic Application), a C# .NET
                                                WebForms application, facilitating Washington Mutual sales agents in
                                                signing up new customers and processing credit card transactions through
                                                a merchant service.
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>

                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <strong>BolderBOULDER</strong>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Designed and developed the BolderBOULDER 2007 race website utilizing
                                                Visual Basic.NET and .NET WebForms. Pioneering the implementation of
                                                RFID chips on participants' shoe laces, the website allowed real-time
                                                race time tracking. Additionally, had the privilege of riding in the
                                                press truck to capture and contribute pictures for the website.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Collaborated in the procurement of AspDotNetStoreFront and seamlessly
                                                integrated it with the BolderBOULDER website.
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>2002 - 2006</strong>
                        &nbsp;&nbsp;
                        Software Engineer / Systems Administrator for EX-CELL Metal Products
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Spearheaded the design and establishment of the initial Active Directory
                                                domain for the organization, streamlining user management and network
                                                access.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Oversaw the administration and maintenance of Gentoo Linux, Windows
                                                servers, and Microsoft SQL servers, ensuring their smooth and secure
                                                operation.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Effectively managed network faxing, Exchange server, and various office
                                                applications, ensuring seamless communication and productivity.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Provided exceptional technical support to employees, resolving
                                                computer-related issues promptly and ensuring smooth operations.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Demonstrated versatility by actively engaging in various business
                                                functions to identify areas for improvement, resulting in the
                                                development of tailored applications to enhance efficiency.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Successfully orchestrated the development and implementation of a
                                                wireless inventory system for the manufacturing plant, optimizing
                                                inventory management, order picking, and facilitating real-time
                                                tracking.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Collaborated with stakeholders to identify gaps in custom application
                                                programming and assisted in the procurement of Syspro MRP for enhanced
                                                performance.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Seamlessly integrated custom applications with Syspro MRP to optimize
                                                business processes and enhance overall efficiency.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Efficiently handled office supplies management and procurement, ensuring
                                                a well-stocked and organized work environment.
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>1998 - 2002</strong>
                        &nbsp;&nbsp;
                        Software Engineer / Computer Engineer for Ethridge Systems
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Designed and implemented a Visual Basic 6 point of sale application for
                                                a Matco tool truck, incorporating barcode functionality and an inline
                                                keyboard barcode scanner for efficient transactions.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Developed a PHP website specifically designed for deployment on field
                                                mudlogging devices. This website facilitated the recording of crucial
                                                mudlogging data, empowering mudloggers to make informed decisions about
                                                the well's potential for oil and gas production, while also ensuring
                                                safe and efficient drilling operations.
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Container className="resume_employmentExperience">
                    <h2>Education</h2>
                </Container>

                <Card className="my-2">
                    <CardHeader>
                        <strong>2010 - 2012</strong>
                        &nbsp;&nbsp;
                        North Seattle Community College
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <p>
                                Studied and worked towards associates in computer engineering
                            </p>
                        </Container>
                    </CardBody>
                </Card>

                <br/>
            </Container>
        );
    }
}