import {Component} from "react";
import {Container} from "reactstrap";
import './ValueStatement.css';

export class ValueStatement extends Component {
    render() {
        return (
            <Container className="valueStatement_container">
                <div className="row">
                    <div className="col-12 text-center">
                        <img className="valueStatement_profilePic" src="images/resumepic.png" alt="Keith Hamilton"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Container className="valueStatement_textContainer">
                            <p>
                                Keith Hamilton is a seasoned technical team leader with extensive expertise in data
                                migrations and integrations, boasting over 15 years of software engineering experience.
                                Proficient in a wide array of programming languages, including C#, VB.NET, Java, and
                                PHP, he possesses a diverse skill set in database management, spanning legacy file-based
                                engines to contemporary relational database solutions.
                            </p>
                            <p>
                                Throughout his career, Keith has successfully developed numerous migrations,
                                integrations, and custom business solutions. His proficiency extends beyond software
                                development; he excels as a systems administrator, well-versed in operating systems like
                                Linux, Windows, MacOS, and OS/X.
                            </p>
                            <p>
                                Keith is highly self-motivated, excels in time management, and works effectively with
                                others, making him an invaluable team player. He is readily available for on-demand
                                projects and demonstrates his in-depth knowledge by adeptly analyzing source systems,
                                effectively migrating them to sophisticated, improved solutions. Additionally, he
                                exhibits a keen ability to profile data efficiently, swiftly identifying anomalies with
                                precision.
                            </p>
                        </Container>
                    </div>
                </div>
            </Container>
        );
    }
}