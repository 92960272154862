import React, {useState} from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Label
} from 'reactstrap';
import './NavMenu.css';

function NavMenu(args) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Navbar color="dark" light={false} dark={true} full={true} expand="md">
                <NavbarBrand href="/">
                    <img src="images/keithavatar.png" alt="Keith Hamilton" className="navmenu_profilePic"/>
                    <Label>Keith Hamilton</Label></NavbarBrand>
                <NavbarToggler onClick={toggle}/>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar>
                        <NavItem>
                            <NavLink href="/resume">Resume</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/personal-projects">Personal Projects</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default NavMenu;