import {Resume} from "./components/Resume";
import {PersonalProjects} from './components/PersonalProjects';
import {ValueStatement} from './components/ValueStatement';

const AppRoutes = [
    {
        index: true,
        element: <ValueStatement/>
    },
    {
        path: '/resume',
        element: <Resume/>
    },
    {
        path: '/personal-projects',
        element: <PersonalProjects/>
    }
];

export default AppRoutes;