import React, {Component} from "react";
import {Card, CardBody, CardFooter, CardHeader, Container} from "reactstrap";
import {Link} from "react-router-dom";
import './PersonalProjects.css';

export class PersonalProjects extends Component {
    render() {
        return (
            <Container>
                <Container className="sampleProjects_header">
                    <h2>Personal Projects</h2>
                </Container>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Keith's Resume</strong>
                    </CardHeader>
                    <CardBody>
                        Keith's Resume site that you are currently viewing now.
                    </CardBody>
                    <CardFooter>
                        <Container className="sampleProjects_cardFooter">
                            <Link to="https://gitlab.com/k31th/resume" target="_blank">Link to Project</Link>
                        </Container>
                    </CardFooter>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>VueTSXCore</strong>
                    </CardHeader>
                    <CardBody>
                        A .NET Core, VueJS, Typescript, TSX boilerplate
                    </CardBody>
                    <CardFooter>
                        <Container className="sampleProjects_cardFooter">

                            <Link to="https://gitlab.com/k31th/vuetsxcore" target="_blank">Link to Project</Link>

                        </Container>
                    </CardFooter>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Piroxsi</strong>
                    </CardHeader>
                    <CardBody>
                        An OpenVPN resource consumer. Designed to turn your Raspberry Pi into a proxy utilizing the wifi
                        as input and the network port as throughput.
                    </CardBody>
                    <CardFooter>
                        <Container className="sampleProjects_cardFooter">
                            <Link to="https://gitlab.com/k31th/piroxsi" target="_blank">Link to Project</Link>
                        </Container>
                    </CardFooter>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>State Server</strong>
                    </CardHeader>
                    <CardBody>
                        A .NET console application that will run modules in their own threads and hold state for
                        different application requests
                    </CardBody>
                    <CardFooter>
                        <Container className="sampleProjects_cardFooter">
                            <Link to="https://gitlab.com/k31th/stateserver" target="_blank">Link to Project</Link>
                        </Container>
                    </CardFooter>
                </Card>

            </Container>
        );
    }
}